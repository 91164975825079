import { firebaseApp } from "@/firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

const db = getFirestore(firebaseApp);

export interface FlexyFile {
  createdAt: any;
  createdBy: string;
  url: string;
  thumbs?: {
    small: string;
    medium: string;
    large: string;
  };
  title?: string;
  description?: string;
  path: string;
  updatedAt: any;
  updatedBy: string;
  version: number;
}

export interface StaticContent {
  id: string;
  title: string;
  slug: string;
  lead: string;
  description: string;
  thumb: FlexyFile;
  subtitle?: string;
  overSubtitle?: string;
  subtitleDescription?: string;
  bullets?: {
    title: string;
    icon: string;
    description: string;
  }[];
  additionalThumb1?: FlexyFile;
  additionalThumb2?: FlexyFile;
  additionalThumb3?: FlexyFile;
  attributes?: {
    [key: string]: {
      description: string;
    };
  };
  texts?: {
    [key: string]: {
      description: string;
    };
  };
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  version?: number;
}

class ContentsService {
  private cachePromise: Promise<StaticContent[]> | null = null;

  findBySlug(slug: string): Promise<StaticContent | undefined> {
    return this.load().then((list) => {
      return list.find((item) => item.slug === slug);
    });
  }

  async load(): Promise<StaticContent[]> {
    if (!this.cachePromise) {
      const citiesCol = collection(db, "contents");
      this.cachePromise = getDocs(citiesCol).then((citySnapshot) => {
        const contents = citySnapshot.docs.map((doc) =>
          doc.data()
        ) as StaticContent[];
        return contents;
      });
    }
    return this.cachePromise;
  }
}

export const contentsService = new ContentsService();
