
import { Options, Vue } from "vue-class-component";
import PageHeadline from "@/common/PageHeadline.vue";
import { contentsService, StaticContent } from "@/services/contents.service";

@Options({
  components: { PageHeadline },
  props: {
    msg: String,
  },
  data() {
    return {
      mobileMenuVisible: false,
      scrollPosition: 0,
      scrollUp: false,
      scrollDown: false,
      sticky: false,
      hide: false,
      showHeaderY: 910,
      page: undefined as StaticContent | undefined
    };
  },
  methods: {
    toggleMenu() {
      if (window.innerWidth >= 1280) {
        return;
      }
      this.mobileMenuVisible = !this.mobileMenuVisible;
      if (this.mobileMenuVisible) {
        this.sticky = true;
      } else {
        if (this.scrollPosition < this.showHeaderY) {
          this.sticky = false;
          this.hide = false;
        }
      }
    },

    updateScroll() {
      if (this.scrollPosition > window.scrollY) {
        this.scrollUp = true;
        this.scrollDown = false;
      } else {
        this.scrollDown = true;
        this.scrollUp = false;
      }

      this.scrollPosition = window.scrollY;

      if (
          this.scrollPosition < this.showHeaderY &&
          this.scrollUp &&
          !this.mobileMenuVisible
      ) {
        if (this.sticky) {
          this.hide = true;
          setTimeout(() => {
            this.hide = false;
            this.sticky = false;
          }, 300);
        }
      }

      if (
          (this.scrollPosition >= this.showHeaderY && this.scrollPosition !== 0) ||
          this.mobileMenuVisible
      ) {
        this.sticky = true;
      }

      if (this.scrollPosition <= 0) {
        this.sticky = false;
      }
    }
  },
  mounted() {
    contentsService.findBySlug("top-menu").then((page) => {
      console.log({page});
      this.page = page as StaticContent;
    });
    window.addEventListener("scroll", this.updateScroll);
  },
})
export default class AppHeader extends Vue {}
