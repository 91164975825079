export function setPageTitle(title = "Rozwiń swoją Inteligencję Emocjonalną") {
  document.title = "EQ Labs" + (title ? " - " + title : "");
}

export function setPageDescription(description?: string) {
  if (!description) {
    description =
      "Delivering the optimal solution for your business with our skilled and driven Experts certified across leading technologies.";
  }
  const descEl = document.querySelector('meta[name="description"]');
  if (descEl) {
    descEl.setAttribute("content", description);
  }
}
