
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";

export default defineComponent({
  name: "CommunicationAndRelationsHeader",
  data() {
    return {
      page: undefined as StaticContent | undefined,
    };
  },
  mounted() {
    contentsService.findBySlug("communication-and-relations").then((page) => {
      this.page = page as StaticContent;
    });
  },
});
