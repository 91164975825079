
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageHeader",
  props: {
    title: String,
    className: String as () => string | null,
  },
});
