import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "./views/Home.vue";
import StaticPage from "@/views/StaticPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/faq/:slug",
    component: () => import("./modules/faq/FaqPage.vue"),
  },

  {
    path: "/exercises/:slug",
    component: () => import("./modules/exercises/ExercisesPage.vue"),
  },

  {
    path: "/warsztaty",
    component: () => import("./modules/workshops/Workshop.vue"),
  },
  {
    path: "/materialy-szkoleniowe",
    component: () => import("./modules/matirials/TeachingMaterials.vue"),
  },
  {
    path: "/articles/:slug",
    component: () => import("./modules/articles/Articles.vue"),
  },
  {
    path: "/articles",
    component: () => import("./modules/articles/Articles.vue"),
  },
  {
    path: "/page/:slug",
    name: "StaticPage",
    component: StaticPage,
    meta: {
      slug: "cookie-policy",
    },
  },
  {
    path: "/kontakt",
    component: () => import("./modules/contact/ContactAplicate.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: function (to) {
    if (to.hash) {
      return window.scrollTo({
        top:
          (document.querySelector(to.hash) as HTMLInputElement).offsetTop -
          (document.querySelector(".header") as HTMLInputElement).offsetHeight,
        behavior: "smooth",
      });
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes,
});

export default router;
