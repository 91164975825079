import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCt1LGbypBebM1hlxhliTZ2XI2cIBHuh9c",
  authDomain: "eq-labs.firebaseapp.com",
  projectId: "eq-labs",
  storageBucket: "eq-labs.appspot.com",
  messagingSenderId: "815730202262",
  appId: "1:815730202262:web:7ad0cbf32ea7ec009e94b7",
  measurementId: "G-WMN5XFVV3J",
};

// firebase.initializeApp(config);
//
// export default firebase.database();

export const firebaseApp = initializeApp(firebaseConfig);
