
import { defineComponent } from "vue";
import { GenericContent } from "@/services/paginator.service";
import Thumb from "@/common/Thumb.vue";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";

export default defineComponent({
  name: "PageHeadline",
  components: { Thumb },
  props: {
    content: Object as () => GenericContent,
  },
  mounted() {
    this.setMeta();
  },
  watch: {
    content() {
      this.setMeta();
    },
  },
  methods: {
    setMeta() {
      setPageTitle(this.content?.title);
      setPageDescription(this.content?.lead);
    },
  },
});
