
import { Options, Vue } from "vue-class-component";
import PageHeadline from "@/common/PageHeadline.vue";
import { StaticContactContent } from "@/models/static-contact.model";
import { contentsService } from "@/services/contents.service";

@Options({
  components: { PageHeadline },
  props: {
    msg: String,
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      page: undefined as StaticContactContent | undefined,
    };
  },
  mounted() {
    contentsService.findBySlug("contact").then((page) => {
      this.page = page as StaticContactContent;
    });
  },
})
export default class AppFooter extends Vue {}
