
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import { breakSingleLetter } from "@/common/breakSingleLetter.filter";

export default defineComponent({
  name: "EmotionalIntelligenceEq",
  data() {
    return {
      page: undefined as StaticContent | undefined,
    };
  },
  mounted() {
    contentsService.findBySlug("eq").then((page) => {
      this.page = page as StaticContent;
    });
  },
  methods: {
    breakSingleLetter(title: string) {
      return breakSingleLetter(title);
    }
  }
});
