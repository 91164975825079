import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero = _resolveComponent("hero")!
  const _component_about_box = _resolveComponent("about-box")!
  const _component_faq_box = _resolveComponent("faq-box")!
  const _component_emotional_intelligence_eq = _resolveComponent("emotional-intelligence-eq")!
  const _component_emotion = _resolveComponent("emotion")!
  const _component_heading_well_being = _resolveComponent("heading-well-being")!
  const _component_main_well_being = _resolveComponent("main-well-being")!
  const _component_communication_and_relations_header = _resolveComponent("communication-and-relations-header")!
  const _component_communication_and_relations_flip = _resolveComponent("communication-and-relations-flip")!
  const _component_exercises = _resolveComponent("exercises")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_hero),
    _createVNode(_component_about_box),
    _createVNode(_component_faq_box),
    _createVNode(_component_emotional_intelligence_eq),
    _createVNode(_component_emotion),
    _createVNode(_component_heading_well_being),
    _createVNode(_component_main_well_being),
    _createVNode(_component_communication_and_relations_header),
    _createVNode(_component_communication_and_relations_flip),
    _createVNode(_component_exercises)
  ]))
}