import { firebaseApp } from "@/firebase";
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";

const db = getFirestore(firebaseApp);

export interface FlexyFile {
  createdAt: any;
  createdBy: string;
  url: string;
  thumbs?: {
    small: string;
    medium: string;
    large: string;
  };
  title?: string;
  description?: string;
  path: string;
  updatedAt: Date;
  updatedBy: string;
  version: number;
}

export interface GenericContent {
  id: string;
  title: string;
  slug: string;
  lead: string;
  description: string;
  thumb: FlexyFile;
  createdAt?: Date;
  createdBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
  version?: number;
  [key: string]: any;
}

export interface Paginator<T = GenericContent> {
  page: number;
  limit: number;
  count: number;
  isFirst: boolean;
  isLast: boolean;
  list: T[];
}

export enum PaginatorFilterSortDesc {
  Desc = "desc",
  Asc = "asc",
}
export enum PaginatorFilterSortType {
  ByNumber = "number",
  ByString = "string",
  ByDate = "date",
}
export interface PaginatorFilter {
  page: number;
  limit: number;
  sortBy?: string;
  sortDesc?: PaginatorFilterSortDesc;
  sortType?: PaginatorFilterSortType;
}

export function getThumbLinks(file: FlexyFile):
  | {
      small: string;
      medium: string;
      large: string;
    }
  | undefined {
  if (file && file.thumbs) {
    return {
      small: file.url
        .replace(
          encodeURIComponent(file.path),
          encodeURIComponent(file.thumbs.small)
        )
        .replace(".webp", ".jpeg"),
      medium: file.url
        .replace(
          encodeURIComponent(file.path),
          encodeURIComponent(file.thumbs.medium)
        )
        .replace(".webp", ".jpeg"),
      large: file.url
        .replace(
          encodeURIComponent(file.path),
          encodeURIComponent(file.thumbs.large)
        )
        .replace(".webp", ".jpeg"),
    };
  }
  return;
}

function decodeData(content: any): Partial<GenericContent> {
  if (content.createdAt) {
    content.createdAt =
      content.createdAt && typeof content.createdAt.toDate === "function"
        ? content.createdAt.toDate()
        : new Date(content.createdAt);
  }
  if (content.updatedAt) {
    content.updatedAt =
      content.updatedAt && typeof content.updatedAt.toDate === "function"
        ? content.updatedAt.toDate()
        : new Date(content.updatedAt);
  }
  return content;
}

class PaginatorService {
  cache: { [collectionName: string]: GenericContent[] | null } = {};

  findBySlug(
    collectionName: string,
    slug: string
  ): Promise<GenericContent | undefined> {
    return this.load(collectionName).then((list) => {
      return list.find((item) => item.slug === slug);
    });
  }

  paginator(
    collectionName: string,
    filter: PaginatorFilter
  ): Promise<Paginator> {
    return this.load(collectionName).then((all) => {
      const sorted = [...all];

      const sortBy = filter.sortBy || "updatedAt";
      const sortDesc = filter.sortDesc || PaginatorFilterSortDesc.Desc;
      const sortType = filter.sortType || PaginatorFilterSortType.ByDate;

      switch (sortType) {
        case PaginatorFilterSortType.ByDate:
          sorted.sort(
            (a, b) =>
              (a[sortBy] &&
                a[sortBy] instanceof Date &&
                b[sortBy] &&
                b[sortBy] instanceof Date &&
                a[sortBy].getTime() - b[sortBy].getTime()) ||
              0
          );
          break;
        case PaginatorFilterSortType.ByNumber:
          sorted.sort((a, b) => a[sortBy] - b[sortBy]);
          break;
        default:
          sorted.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
      }

      if (sortDesc === PaginatorFilterSortDesc.Desc) {
        sorted.reverse();
      }

      const list = sorted.slice(
        (filter.page - 1) * filter.limit,
        filter.page * filter.limit
      );
      const count = (all && all.length) || 0;
      return {
        page: filter.page,
        limit: filter.limit,
        count,
        list,
        isFirst: filter.page === 1,
        isLast: Math.ceil(count / filter.limit) === filter.page,
      };
    });
  }

  async load(collectionName: string, sort: 'desc' | 'asc' = 'desc'): Promise<GenericContent[]> {
    if (this.cache[collectionName]) {
      return new Promise<GenericContent[]>((resolve) => {
        resolve(this.cache[collectionName] as GenericContent[]);
      });
    } else {
      const queryRef = query(
        collection(db, collectionName),
        where("status", "==", true),
        orderBy("showAt", sort)
      );
      const citySnapshot = await getDocs(queryRef);
      const contents = citySnapshot.docs.map((doc) =>
        doc.data()
      ) as GenericContent[];
      this.cache[collectionName] = contents.map(
        (item) => decodeData(item) as GenericContent
      );
      return contents;
    }
  }
}

export const paginatorService = new PaginatorService();
