
import { FlexyFile } from "@/services/contents.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Thumb",
  props: {
    file: Object as () => FlexyFile | null,
    size: {
      type: String,
      default: "medium",
    },
    className: String as () => string | null,
  },
  data() {
    return {
      filePath: undefined as string | undefined,
    };
  },
  mounted() {
    this.prepare();
  },

  methods: {
    prepare() {
      let size = this.size;
      if (window.innerWidth < 430) {
        size = "small";
      }

      if (this.file && this.file.thumbs) {
        if (size === "small") {
          this.filePath = this.file.url
            .replace(
              encodeURIComponent(this.file.path),
              encodeURIComponent(this.file.thumbs.medium)
            )
            .replace(".webp", ".jpeg");
        } else if (size === "large") {
          this.filePath = this.file.url
            .replace(
              encodeURIComponent(this.file.path),
              encodeURIComponent(this.file.thumbs.large)
            )
            .replace(".webp", ".jpeg");
        } else if (size === "original") {
          this.filePath = this.file.url;
        } else {
          this.filePath = this.file.url
            .replace(
              encodeURIComponent(this.file.path),
              encodeURIComponent(this.file.thumbs.medium)
            )
            .replace(".webp", ".jpeg");
        }
      }
    },
  },
  watch: {
    file() {
      this.prepare();
    },
  },
});
