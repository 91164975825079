import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";


const app = createApp(App);
app.use(router).mount("#app");

app.config.globalProperties.$filters = {
  breakLine(value: string | undefined): string {
    return (value ? '' + value : '').split('||').join('<br>');
  }
}
