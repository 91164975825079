
import { Options, Vue } from "vue-class-component";
import AppFooter from "@/components/AppFooter.vue";
import { contentsService } from "@/services/contents.service";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import AppHeader from "@/components/AppHeader.vue";
import Aos from "aos";

import "aos/dist/aos.css";

@Options({
  components: { AppHeader, AppFooter },
})
export default class App extends Vue {
  mobileMenuVisible = false;

  toggleMenu() {
    this.mobileMenuVisible = !this.mobileMenuVisible;
  }

  created() {
    setPageTitle();
    setPageDescription();
    Aos.init({
      // Global settings:
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
    });
  }

  async mounted() {
    await contentsService.load();
  }
}
