
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import { breakSingleLetter } from "@/common/breakSingleLetter.filter";
import { nl2br } from "@/common/nl2br.filter";

export default defineComponent({
  name: "Emotion",
  data() {
    return {
      page: undefined as StaticContent | undefined,
    };
  },
  mounted() {
    contentsService.findBySlug("eq").then((page) => {
      this.page = page as StaticContent;
    });
  },
  methods: {
    breakSingleLetter(title: string) {
      return breakSingleLetter(title);
    },
    nl2br(title: string) {
      return nl2br(title);
    },
  }
});
