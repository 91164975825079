import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-330da00f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EQ" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", {
            innerHTML: _ctx.breakSingleLetter(_ctx.page?.title)
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h2", {
            innerHTML: _ctx.breakSingleLetter(_ctx.page?.lead)
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("h3", {
          innerHTML: _ctx.breakSingleLetter(_ctx.page?.description)
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}