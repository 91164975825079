
import Hero from "@/components/Hero.vue";
import { defineComponent } from "vue";
import AboutBox from "@/components/AboutBox.vue";
import FaqBox from "@/components/FaqBox.vue";
import EmotionalIntelligenceEq from "@/modules/emotional-intelligence/EmotionalIntelligenceEq.vue";
import Emotion from "@/modules/emotional-intelligence/Emotion.vue";
import HeadingWellBeing from "@/modules/well-being/HeadingWellBeing.vue";
import MainWellBeing from "@/modules/well-being/MainWellBeing.vue";
import Exercises from "@/modules/well-being/Exercises.vue";
import CommunicationAndRelationsHeader from "@/modules/communication-and-relations/Header.vue";
import CommunicationAndRelationsFlip from "@/modules/communication-and-relations/Filp.vue";

export default defineComponent({
  name: "Home",
  components: {
    CommunicationAndRelationsFlip,
    CommunicationAndRelationsHeader,
    EmotionalIntelligenceEq,
    FaqBox,
    AboutBox,
    Hero,
    Emotion,
    HeadingWellBeing,
    MainWellBeing,
    Exercises,
  },
  data() {
    return {
      showAll: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", () => {
      let lastKnownScrollPosition = window.scrollY;
      if (lastKnownScrollPosition > 400) {
        this.showAll = true;
      }
    });
  },
});
