
import { defineComponent } from "vue";
import { paginatorService } from "@/services/paginator.service";
import { breakSingleLetter } from "@/common/breakSingleLetter.filter";

export default defineComponent({
  name: "FaqBox",
  data() {
    return {
      list: [] as any[] | undefined,
    };
  },
  mounted() {
    paginatorService.load('faq', 'asc').then((list) => {
      this.list = list
        .filter((item) => !!item.thumb)
        .map((item) => {
          const link = item.thumb.url;
          if (link) {
            return {
              id: item.id,
              url: link,
              title: item.title,
              slug: item.slug
            };
          }
        });
    });
  },
  methods: {
    breakSingleLetter(title: string) {
      return breakSingleLetter(title);
    }
  },
});
