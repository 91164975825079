
import { defineComponent } from "vue";
import { getThumbLinks, paginatorService } from "@/services/paginator.service";

export default defineComponent({
  name: "Exercises",
  data() {
    return {
      list: [] as any[] | undefined,
    };
  },
  mounted() {
    paginatorService.load('exercises', 'asc').then((list) => {
      this.list = list
        .filter((item) => !!item.thumb)
        .map((item) => {

          const link = getThumbLinks(item.thumb);
          if (link) {
            return {
              id: item.id,
              url: link?.small,
              title: item.title,
              slug: item.slug
            };
          }
        });
    });
  },
});
